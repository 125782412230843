<template>
  <CommonTooltip :tooltip="$t('cars-list.sca-tooltip')">
    <DynamicAuctionItemListCarIcon
      variant="blue"
      size="small"
      :href="`#popup-${slug}`"
      v-bind="$attrs"
      @click.stop="handleClickLink"
    >
      <i class="ab-icon ab-icon-sca pointer-events-none h-full"></i>
    </DynamicAuctionItemListCarIcon>
  </CommonTooltip>
</template>

<script lang="ts" setup>
import { usePopupStore } from '@autobid/nuxt-pinia-store/store/usePopupStore'

interface Props {
  slug: string
}

defineProps<Props>()

const { handleClickLink } = usePopupStore()
</script>
